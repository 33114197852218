import {AsyncDirective, directive} from 'lit-html/async-directive.js'

class ResolvePromise extends AsyncDirective {
    render(promise) {
      Promise.resolve(promise).then((resolvedValue) => {
        this.setValue(resolvedValue);
      }, (errorValue) => {
        this.setValue(errorValue);
      });
      /// return `loading...`;
      return ``;
    }
  }
  
  export const untilOrError = directive(ResolvePromise);
  