import {LitElement, unsafeCSS} from 'lit';
import {router} from './router';
import sharedStyles from './style-shared.scss?inline'
import {setBasePath} from '@shoelace-style/shoelace/dist/utilities/base-path.js';
import {gestures} from '@composi/gestures'
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/fr.js';
import {until} from "lit/directives/until.js";
import {untilOrError} from "./untilOrError.js";

dayjs.locale('fr')
dayjs.extend(advancedFormat)
dayjs.extend(timezone)
dayjs.extend(utc)

gestures()

setBasePath('shoelace');

export class AppIndex extends LitElement {
    static styles = [unsafeCSS(sharedStyles)]

    firstUpdated(_changedProperties) {
        router.addEventListener("route", async (e) => {
            this.route = e.detail
            if ("startViewTransition" in document) {
                return document.startViewTransition(() => {
                    this.requestUpdate();
                });
            } else {
                this.requestUpdate();
            }
        })
    }

    render() {
        return untilOrError(this.route?.render(this.route));
    }
}

if (!customElements.get('app-index', AppIndex)) {
    customElements.define('app-index', AppIndex)
}