import "urlpattern-polyfill"
import {html} from "lit";

function mixinEmitter(obj) {
    ["addEventListener", "dispatchEvent", "removeEventListener"]
        .forEach((method) => obj[method] = window[method].bind(window))
}

/**
 * @method addEventListener()
 * @method removeEventListener()
 * @method dispatchEvent()
 */
class Router {

    routes = []
    #fallback = '/';

    constructor() {
        mixinEmitter(this);
    }

    get url() {
        return window.location.hash.substring(1)
    }

    #load = () => {
        this.route(this.url)
    }

    #hashchange = () => {
        this.route(this.url)
    }

    route(url) {
        url = url || this.#fallback
        if (!(url instanceof URL)) {
            url = new URL(url, document.baseURI)
        }
        const route = this.find(url);
        if (route) {
            this.dispatchEvent(new CustomEvent('route', {detail: route}))
        } else {
            window.location.hash = `#${this.#fallback}`
        }
    }

    find(url) {
        const query = Object.fromEntries(new URLSearchParams(url.search));
        let match
        const route = this.routes.find(route => match = route.urlPattern.exec(url))
        if (match) {
            return {
                ...route,
                params: match.pathname.groups,
                query
            }
        }
    }

    enable() {
        window.addEventListener("load", this.#load);
        window.addEventListener("hashchange", this.#hashchange);
        this.#load()
        return this
    }

    disable() {
        window.removeEventListener("load", this.#load);
        window.removeEventListener("hashchange", this.#hashchange);
        return this
    }

    add(route) {
        // yarn add urlpattern-polyfill
        route.urlPattern = new URLPattern({pathname: route.url});
        this.routes.push({...route})
        return this
    }

    fallback(url) {
        this.#fallback = url
        return this
    }
}

export const router = window.router = new Router()

router.fallback('/app/agenda')
    .add({
        url: '/app/agenda',
        render: (route) => import('./page-agenda.js').then(() => html`
                <page-agenda day="${route.query.day}" time="${route.query.time}"></page-agenda>`)
    })
    .add({
        url: '/app/config',
        render: () => import('./page-config.js').then(() => html`
            <page-config></page-config>`)
    })
    .add({
        url: '/app/topics',
        render: () => import('./page-topics.js').then(() => html`
            <page-topics></page-topics>`)
    })
    .add({
        url: '/app/new-event',
        render: (route) => import('./page-new-event').then(() => html`
            <page-new-event day="${route.query.day}" time="${route.query.time}"></page-new-event>`)
    })
    .add({
        url: '/app/edit-event',
        render: (route) => import('./page-edit-event')
            .then(() => Google.fetchEvent(route.query.gid))
            .then((event) => html`
                <page-edit-event .event="${event}"></page-edit-event>`)
    })
    .add({
        url: '/app/show-event',
        render: (route) => import('./page-show-event')
            .then(() => Google.fetchEvent(route.query.gid))
            .then((event) => html`
                <page-show-event .event="${event}"></page-edit-event>`)
    })
    .enable()


export function goBack() {
    history.back()
}

export function goHome() {
    goAgenda()
}

export function goAgenda(day, time) {
    if (day && time) {
        location = `#/app/agenda?day=${day}&time=${time}`
    } else if (day) {
        location = `#/app/agenda?day=${day}`
    } else if (time) {
        location = `#/app/agenda?time=${time}`
    } else {
        location = `#/app/agenda`
    }
}

export function goConfig() {
    location = '#/app/config'
}

export function goTopics() {
    location = '#/app/topics'
}

export function goNewEvent(day, time) {
    if (day && time) {
        location = `#/app/new-event?day=${day}&time=${time}`
    } else if (day) {
        location = `#/app/new-event?day=${day}`
    } else if (time) {
        location = `#/app/new-event?time=${time}`
    } else {
        location = `#/app/new-event`
    }
}

export function goEditEvent(gid) {
    location = `#/app/edit-event?gid=${gid}`
}

export function goShowEvent(gid) {
    location = `#/app/show-event?gid=${gid}`
}
